@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
  font-family: 'CeraPro';
  src: url('../public/fonts/cera-pro-medium.ttf') format('truetype');
  /* Add additional font formats and sources if needed */
  /* Example for local font file: src: url('/fonts/your-font-file.ttf') format('truetype'); */
}
body {
  font-family: 'CeraPro', sans-serif;
}